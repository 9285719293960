import { Platform } from '@angular/cdk/platform'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { ClientService } from 'src/app/client/services/client-service'
import { LoggingService } from 'src/app/shared'
import { environment } from 'src/environments/environment'
import { ClientDataActions } from 'src/app/client'
import { faDownload, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'psrs-fund-identifier',
  templateUrl: './fund-identifier.component.html',
  styleUrls: ['./fund-identifier.component.scss']
})
export class FundIdentifierComponent implements OnInit {
  constructor (
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private loggingService: LoggingService,
    private titleService: Title,
    public platform: Platform,
    private store: Store,
    private sanitized: DomSanitizer
  ) {}

  faFileDownload: IconDefinition = faDownload
  logoUrl: string = ''
  statusText: string = ''
  loadingText: string = 'Loading Application'
  fundLoaded: boolean = false
  deferredPrompt: any
  supportMsg: SafeHtml

  get shouldRedirectToLogin(): boolean {
    let bypassHashExists = (window.location.hash === '#noinstall' || window.location.hash === `#${this.activatedRoute.snapshot.paramMap.get("clientDomain")}`);
    return bypassHashExists;
  }

  get shouldDisplayInstallScreen(): boolean {
    let bypassHashExists = (window.location.hash === '#install' && window.location.hash !== `#${this.activatedRoute.snapshot.paramMap.get("clientDomain")}`);
    return bypassHashExists;
  }  

  ngOnInit(): void {
    //this.titleService.setTitle('Loading Application...');

    var domain = this.activatedRoute.snapshot.paramMap.get("clientDomain");
    window.location.setClientDomain(domain);
    this.logoUrl = `${environment.clientMediaPath}/icons/android-chrome-192x192.png`; 

    this.clientService.getClientByKey(domain).subscribe({
      next: cl => {
        localStorage.setItem('fundName', cl.name)
        this.store.dispatch(ClientDataActions.loadClientSuccess({ client: cl }))
        if (!cl.active) {
          this.titleService.setTitle(`${cl.name} - Application Locked`)
          this.statusText =
            'The App has been temporarly locked.<br />Please try again later.'
          this.loadingText = cl.name
          return
        }

        this.titleService.setTitle(cl.name)
        this.loadingText = cl.name

        this.promptAddToHomeScreen()
        
        if(this.shouldRedirectToLogin) {
          this.statusText = 'Loading App...';
          this.proceedToApp();
          return;
        }

        if (cl.supportChannel === 'Phone') {
          this.supportMsg = this.sanitized.bypassSecurityTrustHtml(
            `call <a href="tel:${cl.supportPhone}">${cl.supportPhone}</a>`
          )
        } else if (cl.supportChannel === 'Email') {
          this.supportMsg = this.sanitized.bypassSecurityTrustHtml(
            `email <a href="mailto:${cl.supportEmail}">${cl.supportEmail}</a>`
          )
        }

        this.fundLoaded = true
      },
      error: err => {
        this.titleService.setTitle('Unable to Load Application')
        this.loggingService.logTrace(
          `Unable to locate (and load) Fund with key: ${domain}`,
          { fundKey: domain }
        )
        this.loggingService.logException(err, 3)

        this.loadingText = 'Unable to Load Application'
        this.statusText =
          'It cannot be found or may no longer exist.<br/>For support, please contact John Schaffer at (857) 245-8704 or jschaffer@ptg-pension.com.'
      }
    })
  }

  imageLoaded(evt: any): void {
    let imgElem: HTMLElement = evt.path[0] as HTMLElement;
    imgElem.style.display = 'inline-block';
  }

  proceedToApp(): void {
    this.router.navigate([''], {
      fragment: window.location.getClientDomain()
    })
  }

  promptAddToHomeScreen() {
    // Check if the browser supports the 'beforeinstallprompt' event
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent Chrome from automatically showing the prompt
      event.preventDefault();
      
      // Manually trigger the prompt
      (event as any).prompt();
    });
  }
}
